import type { CmsCollection } from 'netlify-cms-core'

import { COLLECTION_NAME } from '../../../../../config/cms'
import { createIdentifierField, makeContentPath } from './utils'

export const CASE_STUDIES_COLLECTION: CmsCollection = {
  label: 'Case Studies',
  label_singular: 'Case Study',
  name: COLLECTION_NAME.CaseStudies,
  folder: makeContentPath(COLLECTION_NAME.CaseStudies),
  create: true,
  format: 'json',
  i18n: true,
  identifier_field: 'identifier',
  summary: '{{fields.summary.title}}',
  fields: [
    createIdentifierField(),
    {
      label: 'Summary',
      name: 'summary',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Title',
          name: 'title',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Description',
          name: 'description',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Alt Description (visible on left side)',
          name: 'descriptionAlt',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Location',
          name: 'location',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Date',
          name: 'date',
          widget: 'datetime',
          time_format: false,
        },
        {
          label: 'Prev Title',
          name: 'previewHeading',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Prev Description',
          name: 'previewDescription',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'YT Video',
      name: 'ytVideoSection',
      widget: 'object',
      i18n: false,
      fields: [
        {
          label: 'link',
          name: 'link',
          widget: 'string',
          i18n: false,
        },
        {
          label: 'aspect ratio',
          name: 'aspectRatio',
          widget: 'string',
          i18n: false,
        },
        {
          label: 'width (automatically 100% on mobile)',
          name: 'width',
          widget: 'string',
          i18n: false,
        },
      ],
    },
    {
      label: 'Products',
      name: 'products',
      widget: 'relation',
      i18n: false,
      collection: COLLECTION_NAME.Products,
      multiple: true,
      search_fields: ['name'],
      value_field: 'identifier',
      display_fields: ['name'],
      required: false,
    },
    {
      label: 'Software',
      name: 'software',
      widget: 'relation',
      i18n: false,
      collection: COLLECTION_NAME.Software,
      multiple: true,
      search_fields: ['name'],
      value_field: 'identifier',
      display_fields: ['name'],
      required: false,
    },
    {
      label: 'Media',
      name: 'media',
      widget: 'object',
      fields: [
        {
          label: 'Preview Image',
          name: 'previewImage',
          widget: 'image',
          // @ts-ignore
          choose_url: false,
        },
        {
          label: 'Carousel',
          label_singular: 'item',
          widget: 'list',
          name: 'items',
          fields: [
            {
              label: 'Image',
              name: 'image',
              widget: 'image',
              // @ts-ignore
              choose_url: false,
              required: false,
            },
            {
              label: 'YouTube link',
              name: 'youtube',
              widget: 'string',
              required: false,
            },
          ],
        },
      ],
    },
    {
      label: 'Article',
      name: 'article',
      widget: 'markdown',
      i18n: true,
    },
    {
      label: 'Highlights',
      label_singular: 'Highlight',
      widget: 'list',
      name: 'highlights',
      i18n: true,
      required: false,
      fields: [
        {
          label: 'Top',
          name: 'top',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Bottom',
          name: 'bottom',
          widget: 'string',
          i18n: true,
        },
      ],
    },
  ],
}
